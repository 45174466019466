import React from 'react'
import PropTypes from 'prop-types'
import sideImage from '../../images/Group 24.svg'

const Stats = ({ stat, stat_text }) => {
  return (
    <div
      className="row highlighted_row breathe"
      style={{ position: 'relative' }}
    >
      <img
        className="hidden-xs hidden-sm"
        src={sideImage}
        style={{
          maxWidth: '8%',
          position: 'absolute',
          top: '20px',
          right: '0',
          zIndex: 999,
          transform: 'translate(0%, -100%)',
          positionAnchor: 'bottom right',
        }}
      />
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <div
              className="number"
              dangerouslySetInnerHTML={{ __html: stat }}
            />

            <p dangerouslySetInnerHTML={{ __html: stat_text }} />
          </div>
        </div>
      </div>
    </div>
  )
}

Stats.propTypes = {
  stat: PropTypes.string.isRequired,
  stat_text: PropTypes.string.isRequired,
}

export default Stats
