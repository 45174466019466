import React from 'react'
import PropTypes from 'prop-types'

const StandardText = ({ standard_text }) => {
  return (
    <div className="row standard-text breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <div
              dangerouslySetInnerHTML={{
                __html: standard_text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
StandardText.propTypes = {
  standard_text: PropTypes.string.isRequired,
}

export default StandardText
