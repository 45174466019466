import React from 'react'
import PropTypes from 'prop-types'
import sideImageA from '../../images/Group 78.svg'
import sideImageB from '../../images/Group 23.svg'

const Summary = ({ summary_text }) => {
  return (
    <div className="row breathe" style={{ position: 'relative' }}>
      <img
        className="hidden-xs hidden-sm"
        src={sideImageA}
        style={{
          maxWidth: '8%',
          position: 'absolute',
          bottom: '-30px',
          right: '0',
          zIndex: 999,
        }}
      />
      <img
        className="hidden-xs hidden-sm"
        src={sideImageB}
        style={{
          maxWidth: '8%',
          position: 'absolute',
          bottom: '-70px',
          right: '0',
          zIndex: 999,
        }}
      />
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <div
              dangerouslySetInnerHTML={{
                __html: summary_text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Summary.propTypes = {
  summary_text: PropTypes.string.isRequired,
}

export default Summary
