import React from 'react'
import PropTypes from 'prop-types'

const Figure = ({ figure_repeater }) => {
  return (
    <div>
      {figure_repeater.map((image, index) => (
        <div
          key={index}
          className={image.figure_width === 'half' ? 'col-md-6' : 'col-md-12'}
        >
          {image.figure_heading && <h6>{image.figure_heading}</h6>}
          <p className="img-wrapper">
            <img
              src={image.figure_image.source_url}
              className="img-responsive-inline"
            />
          </p>
          {image.figure_source && (
            <p className="source">{image.figure_source}</p>
          )}
        </div>
      ))}
    </div>
  )
}
Figure.propTypes = {
  figure_repeater: PropTypes.array.isRequired,
}

export default Figure
